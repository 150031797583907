import React, { ReactElement, useEffect, useState } from 'react'
import { useKeycloak } from '@react-keycloak/web'

import { useVisibleState } from '@foxino/components-common'

import { AlertDialog, Button, Loader, TextInput } from '@app/components'
import { UserRole } from '@app/data'
import { useTranslation } from '@app/locales'
import { useUserRole } from '@app/modules/settings/data/useUserRole'
import { showToast } from '@app/utils/showToast'

import { authStore } from '../store/authStore'
import { IncorrectRole } from './IncorrectRole'

// TODO: remove registration code after beta testing
const REGISTRATION_CODE = 'ms2024'

type Props = {
  children: React.ReactNode | React.ReactNode[] | null
}

export const RoleProvider = ({ children }: Props): ReactElement | null => {
  const { t } = useTranslation(['error', 'common'])

  const { roles } = authStore.useStore((store) => store.userInfo)
  const { addUserRole, pending } = useUserRole()
  const { keycloak } = useKeycloak()
  const [isDialogOpen, openDialog, hideDialog] = useVisibleState()
  const [registrationCode, setDeleteConfirmationText] = useState<string>('')

  useEffect(() => {
    const addRole = async () => {
      const success = await addUserRole(UserRole.TEACHER)

      if (success?.data?.addUserRole) {
        await keycloak.updateToken(3600)
      }
    }

    if (
      roles &&
      !roles.includes(UserRole.TEACHER.toLocaleLowerCase()) &&
      !roles.includes(UserRole.STUDENT.toLocaleLowerCase())
    ) {
      addRole()
    }
  }, [roles?.length])

  const addTeacherRoleToStudent = async () => {
    if (registrationCode === REGISTRATION_CODE) {
      const success = await addUserRole(UserRole.TEACHER)

      if (success?.data?.addUserRole) {
        await keycloak.updateToken(3600)
      }
    } else {
      hideDialog()
      showToast(t('registrationCode.error'), { type: 'error' })
    }
  }

  if (pending) {
    return (
      <div className="flex h-[100vh] w-full items-center justify-center">
        <Loader />
      </div>
    )
  }

  if (roles && roles.length > 0 && !roles.includes(UserRole.TEACHER.toLocaleLowerCase())) {
    return (
      <>
        <AlertDialog
          title={t('registrationCode.title')}
          description={t('registrationCode.description')}
          isOpen={isDialogOpen}
          className="select-none"
          onAbort={hideDialog}
          actionButtons={
            <div className="flex w-full flex-col justify-center gap-4">
              <TextInput
                onPasteCapture={(e) => {
                  e.preventDefault()
                }}
                placeholder={t('registrationCode.placeholder')}
                onChange={(e) => setDeleteConfirmationText(e.target.value)}
                maxLength={20}
              />
              <Button
                mode="error"
                onClick={addTeacherRoleToStudent}
                className="mb-4 w-full"
                disabled={registrationCode === ''}
              >
                {t('submit')}
              </Button>
              <Button mode="secondary" onClick={hideDialog} className="w-full">
                {t('cancel')}
              </Button>
            </div>
          }
        />
        <IncorrectRole onContinueClick={openDialog} />
      </>
    )
  }

  return <>{children}</>
}

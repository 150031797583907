import ReactGA from 'react-ga4'
import * as Sentry from '@sentry/react'
import { AxiosError } from 'axios'
import { ApolloError } from '@apollo/client'

import i18n from '@app/locales/i18n'

import { showToast } from './showToast'

const UNAUTHORIZED_MESSAGE = 'Request failed with status code 401'

// Initialize Firebase
ReactGA.initialize(import.meta.env.VITE_FIREBASE_MEASUREMENT_ID)

export class LogUtils {
  /**
   * Log error to sentry
   * @param err Error received in exception
   * @param fileName Filename where exception was thrown
   * @param functionName Function where exception was thrown
   * @param errorText optional error text
   * @returns void
   */
  static logError = (
    err: any,
    fileName: string,
    functionName: string,
    errorText: string = '',
    shouldShowToast = true
  ): void => {
    if (shouldShowToast) {
      showToast(i18n.t('errorGlobal', { ns: 'common' }), { type: 'error' })
    }

    if (import.meta.env.DEV === true) {
      console.error(`[${new Date().toISOString()}]: ${fileName}:${functionName}:${errorText}`, err)
    } else {
      if (err instanceof Error || err instanceof ApolloError || err instanceof AxiosError) {
        // Do not log unauthorized
        if (err.message.includes(UNAUTHORIZED_MESSAGE)) return

        Sentry.captureException({
          hitType: 'exception',
          fileName,
          functionName,
          errorText,
          timestamp: new Date().toISOString(),
          description: err
        })
      }
    }
  }

  /**
   *
   * @param err Get message from error
   * @returns Message from error as string
   */
  static getMessageFromError = (err: any): string => {
    if (err instanceof Error || err instanceof ApolloError) {
      return err.message
    } else if (typeof err === 'string') {
      return err
    }

    return i18n.t('errorGlobal')
  }

  /**
   * Log event to google analytics
   * @param name Event name
   * @param params Key value pairs to log
   */
  static logEvent = (name: string, params?: { [key: string]: string }) => {
    try {
      ReactGA.send({ hitType: 'event', eventAction: name, eventParams: JSON.stringify(params) })
    } catch (error) {
      this.logError(error, 'LogUtils', 'logEvent')
    }
  }

  /**
   * Log screen name to google analytics
   * @param name Name of the screen
   */
  static logScreenView = (page: string) => {
    try {
      ReactGA.send({ hitType: 'pageview', page })
    } catch (error) {
      this.logError(error, 'LogUtils', 'logEvent')
    }
  }
}

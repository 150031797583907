import React, { ReactElement } from 'react'
import { ApolloProvider } from '@apollo/client'

import { initApolloClient } from '@app/config/apollo/apolloActions'
import { apolloStore } from '@app/modules/common/store/apolloStore'

type Props = {
  children: React.ReactNode | React.ReactNode[] | null
}

initApolloClient()

export const GraphqlProvider = (props: Props): ReactElement | null => {
  const apolloClient = apolloStore.useStore((store) => store.apolloClient)

  return apolloClient ? <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider> : null
}

import {
  ApolloClient,
  LazyQueryResultTuple,
  MutationHookOptions,
  NormalizedCacheObject,
  SubscriptionHookOptions,
  useLazyQuery as useApolloLazyQuery,
  useMutation,
  useQuery as useApolloQuery,
  useSubscription
} from '@apollo/client'
import { OperationVariables, TypedDocumentNode } from '@apollo/client/core/types'
import * as Apollo from '@apollo/client/react/types/types'
import { DefinitionNode, DocumentNode, OperationDefinitionNode } from 'graphql'
import { QueryResultStatus, getQueryResultStatus } from './mergeQueryStatus'

// re-exports from @apollo/client
export { useMutation, useSubscription }
export type { MutationHookOptions, SubscriptionHookOptions }

export type QueryResult<TData> = Apollo.QueryResult<TData> & {
  result?: TData
  status: QueryResultStatus
}

export const parseQuery = <TData, _TVariables>(
  // TODO: fix any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apolloResult: Apollo.QueryResult<any, any>,
  dataPropertyName: string,
  defaultValue?: TData
): QueryResult<TData> => {
  const result: TData = apolloResult.data?.[dataPropertyName] || defaultValue
  const status = getQueryResultStatus(apolloResult)
  return { ...apolloResult, result, status }
}

const isOperationDefinition = (item: DefinitionNode): item is OperationDefinitionNode => {
  return item.kind === 'OperationDefinition'
}

const getOperationName = (query: DocumentNode): string => {
  const operationDefinition: OperationDefinitionNode | undefined = query.definitions.find(isOperationDefinition)
  if (operationDefinition?.name) {
    return operationDefinition.name.value
  }
  throw new Error('GraphQLUtils.useQuery - OperationDefinitionNode or name of OperationDefinitionNode is missing.')
}

export type QueryHookOptions<
  TData,
  TVariables extends OperationVariables = OperationVariables
> = Apollo.QueryHookOptions<TData, TVariables> & {
  defaults?: TData
}
export const getUseQuery =
  (getApolloClient: () => ApolloClient<NormalizedCacheObject> | undefined) =>
  <TData = unknown, TVariables extends OperationVariables = OperationVariables>(
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: QueryHookOptions<TData, TVariables>
  ): QueryResult<TData> => {
    const dataPropertyName: string = getOperationName(query)
    const obj = useApolloQuery<TData, TVariables>(query, { ...options, client: getApolloClient() })

    return parseQuery<TData, TVariables>(obj, dataPropertyName, options?.defaults)
  }

export type LazyQueryHookOptions<
  TData,
  TVariables extends OperationVariables = OperationVariables
> = Apollo.LazyQueryHookOptions<TData, TVariables> & {
  defaults?: TData
}

export const getUseLazyQuery =
  (getApolloClient: () => ApolloClient<NormalizedCacheObject> | undefined) =>
  <TData = unknown, TVariables extends OperationVariables = OperationVariables>(
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: LazyQueryHookOptions<TData, TVariables>
  ): LazyQueryResultTuple<TData, TVariables> => {
    const obj = useApolloLazyQuery<TData, TVariables>(query, { ...options, client: getApolloClient() })

    return obj
  }

import { ApolloError, NetworkStatus } from '@apollo/client'
import { QueryResult } from '@apollo/client/react/types/types'

export type QueryResultStatus = {
  loaded: boolean
  loading: boolean // true if loading
  loadingInitial: boolean // true only during the first load
  error: boolean
  errors: ApolloError[]
}

export const printNetworkStatus = (networkStatus: NetworkStatus) => {
  switch (networkStatus) {
    case NetworkStatus.ready:
      return 'ready'
    case NetworkStatus.setVariables:
      return 'setVariables'
    case NetworkStatus.fetchMore:
      return 'fetchMore'
    case NetworkStatus.refetch:
      return 'refetch'
    case NetworkStatus.poll:
      return 'poll'
    case NetworkStatus.error:
      return 'error'
    case NetworkStatus.loading:
      return 'loading'
    default:
      return 'unknown'
  }
}

export const getQueryResultStatus = (queryResult: QueryResult<any, any>): QueryResultStatus => {
  const loaded: boolean = queryResult.called && !queryResult.loading && !queryResult.error
  const loading: boolean = queryResult.loading
  const loadingInitial: boolean =
    !queryResult.data && !queryResult.previousData && !queryResult.error && queryResult.loading
  const errors: ApolloError[] = queryResult.error ? [queryResult.error!] : []
  const error: boolean = errors.length > 0

  return { loaded, loading, loadingInitial, errors, error }
}

export const mergeQueryStatus = (...statusList: QueryResultStatus[]): QueryResultStatus => {
  const loaded: boolean = statusList.every((status) => status.loaded)
  const loading: boolean = statusList.some((status) => status.loading)
  const loadingInitial: boolean = statusList.some((status) => status.loadingInitial)
  const errors: ApolloError[] = statusList.flatMap((status) => status.errors)
  const error: boolean = errors.length > 0

  return { loaded, loading, loadingInitial, errors, error }
}

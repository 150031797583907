export const getInitials = (fullName: string, separator = ' '): string => {
  const names = fullName.trim().split(separator)
  let initials = names[0].substring(0, 1).toUpperCase()

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase()
  }

  return initials
}

import { useAddUserRoleMutation, UserRole } from '@app/data'
import { LogUtils } from '@app/utils/LogUtils'

export const useUserRole = () => {
  const [addUserRoleMutation, { loading: pending, error }] = useAddUserRoleMutation()

  const addUserRole = async (userRole: UserRole) => {
    try {
      return await addUserRoleMutation({
        variables: { userRole }
      })
    } catch (err) {
      LogUtils.logError(err, 'useUserRole', 'addUserRole')
    }
  }

  return { pending, error, addUserRole }
}

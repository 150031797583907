import { CzechFlagRoundIcon } from '@app/assets/flagIcons/round/CzechFlagRoundIcon'
import { EnglishFlagRoundIcon } from '@app/assets/flagIcons/round/EnglishFlagRoundIcon'
import { FranceFlagRoundIcon } from '@app/assets/flagIcons/round/FranceFlagRoundIcon'
import { GermanFlagRoundIcon } from '@app/assets/flagIcons/round/GermanFlagRoundIcon'
import { ItalyFlagRoundIcon } from '@app/assets/flagIcons/round/ItalyFlagRoundIcon'
import { SpanishFlagRoundIcon } from '@app/assets/flagIcons/round/SpanishFlagRoundIcon'
import { Language } from '@app/data'

export const languageToRoundFlagIcon: Record<Language, typeof EnglishFlagRoundIcon> = {
  ENGLISH: EnglishFlagRoundIcon,
  CZECH: CzechFlagRoundIcon,
  FRENCH: FranceFlagRoundIcon,
  GERMAN: GermanFlagRoundIcon,
  ITALIAN: ItalyFlagRoundIcon,
  SPANISH: SpanishFlagRoundIcon
}

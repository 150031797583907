import { GetSubjectsQuery, GetSubjectsQueryVariables, useGetSubjectsQuery } from '@app/data'
import { QueryHookOptions } from '@app/data/graphqlHooks'

export const useGetSubjects = (baseOptions?: QueryHookOptions<GetSubjectsQuery, GetSubjectsQueryVariables>) => {
  const { data, ...restOfResult } = useGetSubjectsQuery({
    ...baseOptions
  })

  return {
    subjects: data?.subjects || [],
    ...restOfResult
  }
}

import { forwardRef } from 'react'
import classNames from 'classnames'

export type DifficultyType = 'low' | 'middle' | 'high' | string

type Props = React.HTMLProps<HTMLDivElement> & {
  variant: DifficultyType
}

/**
 * @group Components
 * @category Component
 */
export const Difficulty = forwardRef(({ className, variant, ...restProps }: Props, ref: React.Ref<HTMLDivElement>) => {
  return (
    <div className={classNames('flex w-fit flex-col gap-[2px]', className)} ref={ref} {...restProps}>
      <span
        className={classNames(
          'aspect-square h-1 w-1 rounded-full',
          variant === 'high' ? 'bg-negative' : 'bg-grayNeutral-150'
        )}
      />
      <span
        className={classNames(
          'aspect-square h-1 w-1 rounded-full',
          variant === 'high' ? 'bg-negative' : variant === 'middle' ? 'bg-warningBase' : 'bg-grayNeutral-150'
        )}
      />
      <span
        className={classNames(
          'aspect-square h-1 w-1 rounded-full',
          variant === 'high' ? 'bg-negative' : variant === 'middle' ? 'bg-warningBase' : 'bg-positive'
        )}
      />
    </div>
  )
})

Difficulty.displayName = 'Difficulty'

import classnames from 'classnames'

import { Typography, TypographyProps, WeightTextType } from '.'

type Props = Omit<TypographyProps, 'variant'> & {
  size?: BodyTextSizeType
}
type BodyTextSizeType = 'm' | 's'

const sizeToStyle: Record<BodyTextSizeType, React.ComponentProps<'p'>['className']> = {
  m: 'text-base', // 16px
  s: 'text-sm tracking-[-0.12px]' // 14px
}

const weightToStyle: Record<WeightTextType, React.ComponentProps<'p'>['className']> = {
  bold: 'font-bold',
  semibold: 'font-semibold',
  medium: 'font-medium',
  regular: 'font-normal'
}

/**
 * @group Components
 * @category Atoms
 */
export const Body = ({ className, weight = 'regular', size = 'm', Component = 'p', children, ...props }: Props) => {
  const sizeStyle = sizeToStyle[size]
  const weightStyle = weightToStyle[weight]

  return (
    <Typography Component={Component} className={classnames(sizeStyle, weightStyle, className)} {...props}>
      {children}
    </Typography>
  )
}

import { forwardRef, ReactNode } from 'react'
import classNames from 'classnames'

type Props = React.HTMLProps<HTMLInputElement> & {
  onClick?: () => void
  title?: string
  icon?: ReactNode
  isActive?: boolean
}

/**
 * @group Components
 * @category Component
 */
export const Tab = forwardRef(
  ({ className, title, onClick, icon, isActive, ...restProps }: Props, ref: React.Ref<HTMLInputElement>) => {
    return (
      <div className={classNames('flex h-14 flex-col gap-1', className)} ref={ref} {...restProps}>
        <div
          className={classNames(
            'hover:bg-grayNeutral-100 flex h-full flex-row items-center justify-center gap-1 rounded-lg px-2 py-[10px] text-sm text-neutral-600 hover:cursor-pointer',
            isActive && 'font-bold'
          )}
          onClick={onClick}
        >
          {icon}
          {title}
        </div>

        <div
          className={classNames(
            'bg-primary transition-width h-[2px] duration-300 ease-in-out',
            isActive ? 'w-full' : 'w-[0px]'
          )}
        />
      </div>
    )
  }
)

Tab.displayName = 'Tab'

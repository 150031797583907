import { ReactElement, ReactNode, useCallback, useEffect, useMemo } from 'react'
import { AuthClientEvent, AuthClientInitOptions, AuthClientTokens } from '@react-keycloak/core/lib/types'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import Keycloak from 'keycloak-js'

import { Constants } from '@app/config/constants/Constants'

import { initAuth } from '../actions/authActions'
import { AuthState } from '../models/AuthState'
import { UserInfoDTO } from '../models/UserInfoDTO'
import { authStore } from '../store/authStore'

const keycloak = new Keycloak({
  url: Constants.AUTH_ENDPOINT,
  clientId: Constants.KEYCLOAK_CLIENT_ID,
  realm: Constants.DEFAULT_TENANT
})

const useHandleKeycloakTokens = () => {
  return useCallback((tokens: AuthClientTokens) => {
    if (tokens.token) {
      authStore.saveAccessToken(tokens.token)
      const decodedInfo = jwtDecode<JwtPayload & UserInfoDTO>(tokens.token)
      const { preferred_username, email, family_name, given_name, name, realm_access } = decodedInfo
      authStore.setUserInfo({
        userId: preferred_username,
        email,
        firstName: given_name,
        fullName: name,
        lastName: family_name,
        roles: realm_access?.roles || []
      })
    } else {
      authStore.saveAccessToken(undefined)
    }

    if (tokens.refreshToken) {
      authStore.saveRefreshToken(tokens.refreshToken)
    } else {
      authStore.saveRefreshToken(undefined)
    }

    if (tokens.idToken) {
      authStore.saveIdToken(tokens.token)
    } else {
      authStore.saveIdToken(undefined)
    }

    authStore.setAuthState(tokens.token ? AuthState.AUTHENTICATED : AuthState.NEED_TO_LOGIN)
  }, [])
}

type Props = {
  children: ReactNode
}

export const KeycloakProvider = (props: Props): ReactElement => {
  const handleNewTokens = useHandleKeycloakTokens()

  const {
    idToken: initialIDToken,
    refreshToken: initialRefreshToken,
    accessToken: initialToken
  } = authStore.useStore.getState()

  const initOptions = useMemo<AuthClientInitOptions>(
    () => ({
      onLoad: 'login-required',
      // flow: "hybrid",
      enableLogging: true,
      checkLoginIframe: false,
      refreshToken: initialRefreshToken,
      idToken: initialIDToken,
      token: initialToken,
      timeSkew: 1,
      // TODO: remove when there is no need for registration code
      scope: 'openid offline_access'
    }),
    [initialIDToken, initialRefreshToken, initialToken]
  )

  const handleOnEvent = (eventType: AuthClientEvent) => {
    if (eventType === 'onAuthError') {
      keycloak.clearToken()
      keycloak.logout()
    }
  }

  useEffect(() => {
    initAuth()
    authStore.useStore.setState({ keycloak })
  }, [])

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onTokens={handleNewTokens}
      initOptions={initOptions}
      onEvent={handleOnEvent}
      autoRefreshToken
    >
      {props.children}
    </ReactKeycloakProvider>
  )
}

import { forwardRef } from 'react'
import classnames from 'classnames'

export type WeightTextType = 'bold' | 'semibold' | 'medium' | 'regular'

export type TypographyProps = {
  className?: string
  weight?: WeightTextType
  Component?: React.ElementType
} & React.HTMLAttributes<HTMLParagraphElement>

/**
 * @group Components
 * @category Atoms
 */
export const Typography = forwardRef<HTMLParagraphElement, TypographyProps>(
  ({ className, weight = 'regular', Component = 'p', children, ...props }, ref) => {
    return (
      <Component className={classnames('text-light-black', className)} ref={ref} weight={weight} {...props}>
        {children}
      </Component>
    )
  }
)

Typography.displayName = 'Typography'

import { Link } from 'react-router-dom'
import classNames from 'classnames'
import * as Tooltip from '@radix-ui/react-tooltip'
import { PlusCircle } from '@untitled-ui/icons-react'

import { Body } from '@app/components'
import { RouteType } from '@app/config/router/navMenu'

type Props = RouteType & {
  isActive: boolean
  isExpanded: boolean
  onClick?: () => void
}

export const NewClassNavigationItem = (props: Props) => {
  const { to, disabled, isActive, isExpanded, onClick } = props

  if (onClick) {
    return (
      <div
        onClick={onClick}
        className={classNames(
          'justify-left relative flex w-full cursor-pointer items-center gap-2 overflow-hidden rounded-md border-[1px] border-primary-600 px-[10px] text-sm',
          isActive
            ? 'rounded-lg bg-primary-500 text-primary-100 shadow-sm'
            : 'bg-primary-700 text-primary-300 hover:bg-primary-600',
          isExpanded && 'pl-6'
        )}
      >
        <LinkContent {...props} />
      </div>
    )
  }

  return (
    <Link
      to={disabled ? '#' : to}
      className={classNames(
        'justify-left relative flex w-full cursor-pointer items-center gap-2 overflow-hidden rounded-md border-[1px] border-primary-600 px-3 text-sm',
        isActive
          ? 'rounded-lg bg-primary-500 text-primary-100 shadow-sm'
          : 'bg-primary-700 text-primary-300 hover:bg-primary-600',
        isExpanded && 'pl-6'
      )}
    >
      <LinkContent {...props} />
    </Link>
  )
}

const LinkContent = ({ isActive, isExpanded, label }: Props) => {
  return (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={0}>
        {!isExpanded && (
          <Tooltip.Trigger>
            <Body className={classNames('py-2 text-primary-200 transition-all')} weight={isActive ? 'bold' : 'regular'}>
              <PlusCircle />
            </Body>
          </Tooltip.Trigger>
        )}

        <Tooltip.Portal>
          <Tooltip.Content
            sideOffset={15}
            side="right"
            className="ring-grayNeutral-100 rounded-lg bg-white px-[10px] py-2 shadow-2xl ring-1"
          >
            <Body size="m" weight="bold">
              {label}
            </Body>
            <Tooltip.Arrow className="fill-white" />
          </Tooltip.Content>
        </Tooltip.Portal>

        {isExpanded && (
          <div className="flex w-full flex-row items-center justify-between gap-2">
            <Body
              className={classNames('py-3 text-primary-200 transition-all', isActive && isExpanded && 'py-4')}
              weight={isActive ? 'bold' : 'regular'}
            >
              {label}
            </Body>
            <PlusCircle width={32} height={32} />
          </div>
        )}
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}

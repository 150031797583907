import { useGetClassroomsQuery } from '@app/data'

export const useClassrooms = (getWithStudents = false) => {
  const { data, loading, error } = useGetClassroomsQuery({ variables: { getWithStudents } })

  return {
    classrooms: data?.classrooms,
    error,
    loading
  }
}

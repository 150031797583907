import { Settings01 } from '@untitled-ui/icons-react'

import i18n from '@app/locales/i18n'
import { IconType } from '@app/model/common/IconType'

import { Pages } from './PagesEnum'

export type RouteType = {
  to: string
  label: string
  Icon?: IconType
  position?: 'bottom' | 'top' | 'middle'
  disabled?: boolean
  children?: RouteType[]
}

export const navMenu: RouteType[] = [
  {
    to: Pages.SETTINGS,
    label: i18n.t('routes.settings'),
    Icon: Settings01,
    position: 'bottom'
  }
]

/* eslint-disable react-refresh/only-export-components */
import React from 'react'
import { createBrowserRouter } from 'react-router-dom'

import { BasicLayout, PageNotExist } from '@app/modules/common/view'

import { ErrorElement } from './ErrorElement'
import { Pages } from './PagesEnum'

const ClassroomPage = React.lazy(() => import('@app/modules/classroom/view/ClassroomPage/ClassroomPage'))
const NewClassPage = React.lazy(() => import('@app/modules/classroom/view/NewClassPage/NewClassPage'))
const SettingsPage = React.lazy(() => import('@app/modules/settings/view/SettingsPage/SettingsPage'))

export const router = createBrowserRouter([
  {
    path: Pages.ROOT,
    element: <BasicLayout />,
    errorElement: <ErrorElement />,
    children: [
      {
        index: true,
        element: <ClassroomPage />
      },
      {
        path: Pages.NEW_CLASS,
        element: <NewClassPage />
      },
      {
        path: Pages.CLASSROOM_DETAIL(':id'),
        element: <ClassroomPage />
      },
      {
        path: Pages.SETTINGS,
        element: <SettingsPage />
      }
    ]
  },
  {
    path: '*',
    element: <PageNotExist />
  }
])

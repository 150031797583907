import React, { ReactElement, SVGProps } from 'react'

export const SpanishFlagRoundIcon = React.forwardRef(
  ({ width = 24, height = 24, ...props }: SVGProps<SVGSVGElement>, ref: React.Ref<SVGSVGElement>): ReactElement => {
    return (
      <svg
        width={width}
        height={height}
        ref={ref}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_585_29801)">
          <path
            d="M0 11.9993C0 13.4671 0.264047 14.8732 0.746391 16.1732L12 17.2166L23.2536 16.1732C23.736 14.8732 24 13.4671 24 11.9993C24 10.5314 23.736 9.12536 23.2536 7.82537L12 6.78189L0.746391 7.82537C0.264047 9.12536 0 10.5314 0 11.9993Z"
            fill="#FFDA44"
          />
          <path
            d="M23.255 7.82574C21.5595 3.25618 17.161 -0.000366211 12.0014 -0.000366211C6.84183 -0.000366211 2.44332 3.25618 0.747803 7.82574H23.255Z"
            fill="#D80027"
          />
          <path
            d="M0.746826 16.174C2.44234 20.7435 6.84086 24.0001 12.0004 24.0001C17.16 24.0001 21.5585 20.7435 23.254 16.174H0.746826Z"
            fill="#D80027"
          />
        </g>
        <defs>
          <clipPath id="clip0_585_29801">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )
  }
)

SpanishFlagRoundIcon.displayName = 'SpanishFlagRoundIcon'

import React, { MouseEventHandler } from 'react'
import classNames from 'classnames'

import { Button, Heading } from '@app/components'
import { Loader } from '@app/components'
import { useTranslation } from '@app/locales'

type SizeType = 'large' | 'medium' | 'small' | 'xs'

type Props = Omit<React.HTMLProps<HTMLDivElement>, 'size'> &
  React.PropsWithChildren & {
    size?: SizeType
    showUnderOpacity?: boolean
    showChildrenOnLoading?: boolean
    loading: boolean
    error?: any
    refetch?: () => void
  }

/**
 * @group Components
 * @category Props
 */
export const LoaderWrapper = ({
  size = 'large',
  showUnderOpacity = true,
  showChildrenOnLoading = false,
  loading = true,
  children,
  error,
  refetch,
  className,
  ...restProps
}: Props) => {
  const { t } = useTranslation('common')

  if ((!loading || showChildrenOnLoading) && !error) {
    return children
  }

  const handleOnClick: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation()
  }

  return (
    <div
      onClick={handleOnClick}
      {...restProps}
      className={classNames('flex h-full w-full flex-row items-center justify-center')}
    >
      {error ? (
        <div className="bg-negativeLighter flex w-full items-center justify-center rounded-xl p-4 shadow-xl">
          <div className="flex flex-col items-center">
            <Heading size="l">{t('errorTitle')}</Heading>
            <Heading size="m">{t('errorGlobal')}</Heading>
          </div>

          {refetch && <Button>{t('refetch')}</Button>}
        </div>
      ) : (
        <div className="relative h-full w-full">
          <div className={classNames('h-full w-full', showUnderOpacity ? 'opacity-20' : 'opacity-0', className)}>
            {children}
          </div>
          <div className="absolute right-[calc(50%_-_1.5rem)] top-[40%]">
            <Loader size={size} />
          </div>
        </div>
      )}
    </div>
  )
}

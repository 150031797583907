import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

type ApolloStoreType = {
  isDialogOpen: boolean
}

const useStore = createWithEqualityFn<ApolloStoreType>(() => ({ isDialogOpen: false }), shallow)

export const createClassStore = {
  setDialogOpen: (isDialogOpen: boolean): void => {
    useStore.setState({ isDialogOpen })
  },

  useStore
}

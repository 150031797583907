import Keycloak from 'keycloak-js'
import { createJSONStorage, persist } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { AuthState } from '../models/AuthState'

type TenantInfo = {
  name: string
  realm: string
  keycloakUri: string
}

type UserInfoType = {
  userId: string
  firstName: string
  lastName: string
  email: string
  fullName: string
  roles: string[] | null
}

type AuthStoreType = {
  tenant?: TenantInfo
  authState: AuthState
  accessToken?: string
  refreshToken?: string
  idToken?: string
  refreshTokenTimerId?: NodeJS.Timeout
  keycloak?: Keycloak
  userInfo: UserInfoType
}

const initialState: AuthStoreType = {
  authState: AuthState.UNKNOWN,
  userInfo: {
    email: '',
    firstName: '',
    fullName: '',
    lastName: '',
    userId: '',
    roles: null
  }
}

const useStore = createWithEqualityFn(
  persist<AuthStoreType>(() => initialState, {
    name: 'authStore',
    storage: createJSONStorage(() => sessionStorage)
  }),
  shallow
)

export const authStore = {
  setAuthState: (authState: AuthState): void => {
    useStore.setState({ authState })
  },
  getAccessToken: (): string => {
    return useStore.getState().accessToken || ''
  },
  saveAccessToken: (accessToken: string | undefined): void => {
    useStore.setState({ accessToken })
  },
  saveRefreshToken: (refreshToken: string | undefined): void => {
    useStore.setState({ refreshToken })
  },
  saveIdToken: (idToken: string | undefined): void => {
    useStore.setState({ idToken })
  },
  isAuthorized: (): boolean => {
    return useStore.getState().authState === AuthState.AUTHENTICATED
  },
  setRefreshTokenTimerId: (refreshTokenTimerId: NodeJS.Timeout): void => {
    useStore.setState({ refreshTokenTimerId })
  },
  setUserInfo: (userInfo: UserInfoType): void => {
    useStore.setState({ userInfo })
  },
  clearRefreshTokenTimerId: (): void => {
    useStore.setState({ refreshTokenTimerId: undefined })
  },
  clearStore: (): void => {
    useStore.setState(initialState, true)
  },
  useStore
}

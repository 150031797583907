import { useLocation } from 'react-router-dom'
import * as Tooltip from '@radix-ui/react-tooltip'

import { Accordion, Label } from '@app/components'
import { RouteType } from '@app/config/router/navMenu'

import { checkUrlIsActive } from '../../../utils/checkUrlIsActive'
import { NavigationItem } from './NavigationItem'

type Props = Omit<RouteType, 'to'> & {
  isActive: boolean
  isExpanded: boolean
  routes: RouteType[]
}

export const CollapsibleWrapper = ({ Icon, isActive, isExpanded, label, routes }: Props) => {
  const location = useLocation()

  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Portal>
          <Tooltip.Content sideOffset={15} side="right" className="rounded bg-white px-[10px] py-2 shadow-xl">
            <Label size="m" weight={isActive ? 'bold' : 'semibold'}>
              {label}
            </Label>
            <Tooltip.Arrow className="fill-white" />
          </Tooltip.Content>
        </Tooltip.Portal>

        <Accordion
          label={isExpanded ? <span className={isActive ? 'font-semibold' : ''}>{label}</span> : undefined}
          className="cursor-pointer"
          icon={
            isExpanded && Icon ? (
              <Tooltip.Trigger disabled={!isExpanded}>
                <Icon className={isActive ? 'text-primary-500' : 'text-neutral-500'} />
              </Tooltip.Trigger>
            ) : undefined
          }
        >
          <div className="">
            {routes.map((item, index) => (
              <NavigationItem
                {...item}
                key={index}
                isActive={checkUrlIsActive(item, location)}
                isExpanded={isExpanded}
              />
            ))}
          </div>
        </Accordion>
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}

import FirstPlace from '../../assets/images/classrooms/activities/1st_place.png'
import SecondPlace from '../../assets/images/classrooms/activities/2nd_place.png'
import ThirdPlace from '../../assets/images/classrooms/activities/3rd_place.png'
import ConnectionCard from '../../assets/images/classrooms/activities/connection_card.png'
import ConnectionCorner from '../../assets/images/classrooms/activities/connection_corner.png'
import FlashingLights from '../../assets/images/classrooms/activities/flashing_lights.png'
import FlipcardsCard from '../../assets/images/classrooms/activities/flipcards_card.png'
import FlipcardsCorner from '../../assets/images/classrooms/activities/flipcards_corner.png'
import FlipcardsPreview from '../../assets/images/classrooms/activities/flipcards_preview_image.png'
import HomeworkCard from '../../assets/images/classrooms/activities/homework_card.png'
import HomeworkCardSmall from '../../assets/images/classrooms/activities/homework_card_small.png'
import HomeworkCorner from '../../assets/images/classrooms/activities/homework_corner.png'
import HomeworkIcon from '../../assets/images/classrooms/activities/homework_icon.png'
import HomeworkPreview from '../../assets/images/classrooms/activities/homework_preview_image.png'
import PexesoGameCard from '../../assets/images/classrooms/activities/pexeso_card.png'
import PexesoCorner from '../../assets/images/classrooms/activities/pexeso_corner.png'
import PexesoIcon from '../../assets/images/classrooms/activities/pexeso_icon.png'
import PexesoPreview from '../../assets/images/classrooms/activities/pexeso_preview_image.png'
import QuizCard from '../../assets/images/classrooms/activities/quiz_card.png'
import QuizCorner from '../../assets/images/classrooms/activities/quiz_corner.png'
import QuizIcon from '../../assets/images/classrooms/activities/quiz_icon.png'
import QuizPreview from '../../assets/images/classrooms/activities/quiz_preview_image.png'
import SelfLearningIcon from '../../assets/images/classrooms/activities/self_learning_icon.png'
import SelfStudyPreview from '../../assets/images/classrooms/activities/self_learning_preview_image.png'
import SelfStudyCard from '../../assets/images/classrooms/activities/self_study_card.png'
import SelfStudyCorner from '../../assets/images/classrooms/activities/self_study_corner.png'
import Star from '../../assets/images/classrooms/activities/stars.png'
import TeamLeaderboardBg from '../../assets/images/classrooms/activities/team_leaderboard_bg.png'
import TestCard from '../../assets/images/classrooms/activities/test_card.png'
import TestCorner from '../../assets/images/classrooms/activities/test_corner.png'
import TestIcon from '../../assets/images/classrooms/activities/test_icon.png'
import TestPreview from '../../assets/images/classrooms/activities/test_preview_image.png'
import ActivityStar from '../../assets/images/classrooms/activity_star.png'
import ClassroomHeaderSwitch from '../../assets/images/classrooms/classroom_header_switch.svg'
import Stars from '../../assets/images/classrooms/classroom_stars.svg'
import CornerBackground from '../../assets/images/classrooms/corner_background.png'
import CorrectTick from '../../assets/images/classrooms/correct_tick.svg'
import EnglandCrown from '../../assets/images/classrooms/england_crown.png'
import FoxinoDashboard from '../../assets/images/classrooms/foxino_dashboard.png'
import BooksIcon from '../../assets/images/classrooms/progress/books.png'
import MicrophoneIcon from '../../assets/images/classrooms/progress/microphone.png'
import PencilIcon from '../../assets/images/classrooms/progress/pencil.png'
import SchoolBag from '../../assets/images/classrooms/progress/school_bag.png'
import SpeakerIcon from '../../assets/images/classrooms/progress/speaker.png'
import Test from '../../assets/images/classrooms/progress/test.png'
import PhoneBooth from '../../assets/images/classrooms/subject/english/phone_booth.png'
import SchoolBigBen from '../../assets/images/classrooms/subject/english/school_big_ben.png'
import SchoolBus from '../../assets/images/classrooms/subject/english/school_bus.png'
import SchoolDinosaur from '../../assets/images/classrooms/subject/english/school_dinosaur.png'
import ClassBakery from '../../assets/images/classrooms/subject/french/bakery.png'
import ClassEiffelTower from '../../assets/images/classrooms/subject/french/eiffel_tower.png'
import ClassMonaLisa from '../../assets/images/classrooms/subject/french/mona_lisa.png'
import ClassNotreDame from '../../assets/images/classrooms/subject/french/notre_dame.png'
import ClassTourDeFrance from '../../assets/images/classrooms/subject/french/tour_de_france.png'
import ClassGerman1 from '../../assets/images/classrooms/subject/german/german_1.png'
import ClassGerman2 from '../../assets/images/classrooms/subject/german/german_2.png'
import ClassGerman3 from '../../assets/images/classrooms/subject/german/german_3.png'
import ClassGerman4 from '../../assets/images/classrooms/subject/german/german_4.png'
import ClassGerman5 from '../../assets/images/classrooms/subject/german/german_5.png'
import ClassSpanish1 from '../../assets/images/classrooms/subject/spanish/spanish_1.png'
import ClassSpanish2 from '../../assets/images/classrooms/subject/spanish/spanish_2.png'
import ClassSpanish3 from '../../assets/images/classrooms/subject/spanish/spanish_3.png'
import ClassSpanish4 from '../../assets/images/classrooms/subject/spanish/spanish_4.png'
import ClassSpanish5 from '../../assets/images/classrooms/subject/spanish/spanish_5.png'
import BatSteal from '../../assets/images/flipcards/bat_steal.png'
import BatSuperSteal from '../../assets/images/flipcards/bat_super_steal.png'
import CorrectAnswerTick from '../../assets/images/flipcards/correct_answer_tick.svg'
import Crown from '../../assets/images/flipcards/crown.png'
import DoubleHoney from '../../assets/images/flipcards/double_honey.png'
import ExclamationMark from '../../assets/images/flipcards/exclamation_mark.svg'
import ExclamationMarkDisabled from '../../assets/images/flipcards/exclamation_mark_disabled.svg'
import FlipcardsLogo from '../../assets/images/flipcards/flipcards_logo.svg'
import Hamburger from '../../assets/images/flipcards/hamburger.svg'
import HedgeTrade from '../../assets/images/flipcards/hedge_trade.png'
import IncorrectAnswer from '../../assets/images/flipcards/incorrect_mark.svg'
import LeadMouse from '../../assets/images/flipcards/lead_mouse.png'
import PawPattern from '../../assets/images/flipcards/paw_pattern.png'
import Rabbit from '../../assets/images/flipcards/rabbit.png'
import RandomCube from '../../assets/images/flipcards/random_cube.svg'
import TeddyBear from '../../assets/images/flipcards/teddy_bear.png'
import Thanksgiving from '../../assets/images/flipcards/thanksgiving.png'
import TreeThread from '../../assets/images/flipcards/tree_thread.png'
import UnluckyWolf from '../../assets/images/flipcards/unlucky_wolf.png'
import Logo from '../../assets/images/foxino_beta.svg'
import GamesNoContentImage from '../../assets/images/games_no_content.png'
import BlackCard from '../../assets/images/pexeso/black_card_bee.gif'
import BlackCardBackground from '../../assets/images/pexeso/black_card_bg.png'
import GoldenCard from '../../assets/images/pexeso/golden_card.gif'
import PexesoBanner from '../../assets/images/pexeso/pexeso_banner.png'
import PexesoBackground from '../../assets/images/pexeso/pexeso_bg.png'
import PexesoCardCover from '../../assets/images/pexeso/pexeso_card_cover.png'
import PexesoSound from '../../assets/images/pexeso/pexeso_sound.png'
import PexesoStarsBackground from '../../assets/images/pexeso/pexeso_stars.png'
import PexesoUfoBlue from '../../assets/images/pexeso/ufo_blue.png'
import PexesoUfoGreen from '../../assets/images/pexeso/ufo_green.png'
import PexesoUfoPurple from '../../assets/images/pexeso/ufo_purple.png'
import PexesoUfoRed from '../../assets/images/pexeso/ufo_red.png'
import ArrowDown from '../../assets/images/quiz/arrow_drop_down_red.png'
import ArrowUp from '../../assets/images/quiz/arrow_drop_up_green.png'
import BadgeIcon from '../../assets/images/quiz/badge.png'
import QuizCheckmark from '../../assets/images/quiz/checkmark.png'
import QuizCheckmarkLines from '../../assets/images/quiz/checkmark_lines.png'
import QuizConfetti from '../../assets/images/quiz/confetti.png'
import FirstWinner from '../../assets/images/quiz/first_winner.png'
import OkFoxino from '../../assets/images/quiz/ok_foxino_trimmed.png'
import PersonIcon from '../../assets/images/quiz/person.png'
import QuestionFrameBg from '../../assets/images/quiz/question_frame_bg.png'
import QuizRedCross from '../../assets/images/quiz/red_cross.png'
import SamePlace from '../../assets/images/quiz/same_place.png'
import SecondWinner from '../../assets/images/quiz/second_winner.png'
import QuizAssignmentSound from '../../assets/images/quiz/sound.png'
import QuizCircleSymbol from '../../assets/images/quiz/svg/circle_symbol.svg'
import FoxinoQuizGameTitle from '../../assets/images/quiz/svg/foxino_quiz_game_title.svg'
import FoxinoQuizLogo from '../../assets/images/quiz/svg/foxino_quiz_logo.svg'
import QrCodeBg from '../../assets/images/quiz/svg/qr_code_bg.svg'
import QrInputBg from '../../assets/images/quiz/svg/qr_input_bg.svg'
import SandClock from '../../assets/images/quiz/svg/sand_clock.svg'
import QuizSquareSymbol from '../../assets/images/quiz/svg/square_symbol.svg'
import QuizStarSymbol from '../../assets/images/quiz/svg/star_symbol.svg'
import QuizTriangleSymbol from '../../assets/images/quiz/svg/triangle_symbol.svg'
import ThirdWinner from '../../assets/images/quiz/third_winner.png'
import Trophy from '../../assets/images/quiz/trophy.png'
import Speaker from '../../assets/images/speaker.png'
import WrongRights from '../../assets/images/wrong_rights.png'

export const ImageContent = {
  logo: Logo,
  noGames: GamesNoContentImage,
  speaker: Speaker,
  WrongRights,

  pexeso: {
    background: {
      banner: PexesoBanner,
      pexesoStarsBackground: PexesoStarsBackground,
      planets: PexesoBackground
    },
    BlackCard,
    BlackCardBackground,
    goldenCard: GoldenCard,
    cardCover: PexesoCardCover,
    sound: PexesoSound,
    ufo: {
      blue: PexesoUfoBlue,
      green: PexesoUfoGreen,
      purple: PexesoUfoPurple,
      red: PexesoUfoRed
    }
  },

  quiz: {
    svg: {
      qrCodeBg: QrCodeBg,
      qrInputBg: QrInputBg,
      quizGameTitle: FoxinoQuizGameTitle,
      quizLogo: FoxinoQuizLogo,
      sandClock: SandClock,
      square: QuizSquareSymbol,
      circle: QuizCircleSymbol,
      star: QuizStarSymbol,
      triangle: QuizTriangleSymbol,
      hamburger: Hamburger
    },
    arrowUp: ArrowUp,
    arrowDown: ArrowDown,
    samePlace: SamePlace,
    questionFrameBg: QuestionFrameBg,
    lobby: PersonIcon,
    badge: BadgeIcon,
    okFoxino: OkFoxino,
    sound: QuizAssignmentSound,
    trophy: Trophy,
    firstWinner: FirstWinner,
    secondWinner: SecondWinner,
    thirdWinner: ThirdWinner,
    confetti: QuizConfetti,
    checkmark: QuizCheckmark,
    checkmarkLine: QuizCheckmarkLines,
    redCross: QuizRedCross
  },

  flipcards: {
    background: PawPattern,
    logo: FlipcardsLogo,
    hamburger: Hamburger,
    correctAnswerTick: CorrectAnswerTick,
    incorrectAnswer: IncorrectAnswer,
    exclamationMark: ExclamationMark,
    exclamationMarkDisabled: ExclamationMarkDisabled,
    teddyBear: TeddyBear,
    crown: Crown,
    batSteal: BatSteal,
    batSuperSteal: BatSuperSteal,
    hedgeTrade: HedgeTrade,
    leadMouse: LeadMouse,
    treeThread: TreeThread,
    unluckyWolf: UnluckyWolf,
    thanksgiving: Thanksgiving,
    doubleHoney: DoubleHoney,
    rabbit: Rabbit,
    randomCube: RandomCube
  },

  classrooms: {
    subject: {
      ENGLISH: {
        1: PhoneBooth,
        2: SchoolBigBen,
        3: SchoolBus,
        4: SchoolDinosaur
      },
      GERMAN: {
        1: ClassGerman1,
        2: ClassGerman2,
        3: ClassGerman3,
        4: ClassGerman4,
        5: ClassGerman5
      },
      SPANISH: {
        1: ClassSpanish1,
        2: ClassSpanish2,
        3: ClassSpanish3,
        4: ClassSpanish4,
        5: ClassSpanish5
      },
      FRENCH: {
        1: ClassBakery,
        2: ClassEiffelTower,
        3: ClassMonaLisa,
        4: ClassNotreDame,
        5: ClassTourDeFrance
      }
    },
    correctTick: CorrectTick,
    englandCrown: EnglandCrown,
    stars: Stars,
    cornerBackground: CornerBackground,
    foxinoDashboard: FoxinoDashboard,
    headerSwitch: ClassroomHeaderSwitch,
    cards: {
      homeworkCardSmall: HomeworkCardSmall,
      homeworkCard: HomeworkCard,
      flipcardsCard: FlipcardsCard,
      quizCard: QuizCard,
      selfStudyCard: SelfStudyCard,
      testCard: TestCard,
      activityStar: ActivityStar,
      pexesoCard: PexesoGameCard,
      connectionCard: ConnectionCard
    },
    activities: {
      connectionCorner: ConnectionCorner,
      flipcardsCorner: FlipcardsCorner,
      homeworkCorner: HomeworkCorner,
      pexesoCorner: PexesoCorner,
      quizCorner: QuizCorner,
      testCorner: TestCorner,
      selfStudyCorner: SelfStudyCorner,
      selfStudyPreview: SelfStudyPreview,
      quizPreview: QuizPreview,
      testPreview: TestPreview,
      pexesoPreview: PexesoPreview,
      homeworkPreview: HomeworkPreview,
      flipcardsPreview: FlipcardsPreview,
      teamLeaderboardBg: TeamLeaderboardBg,
      firstPlace: FirstPlace,
      secondPlace: SecondPlace,
      thirdPlace: ThirdPlace,
      flashingLights: FlashingLights,
      star: Star,
      icons: {
        testIcon: TestIcon,
        homeworkIcon: HomeworkIcon,
        quizIcon: QuizIcon,
        selfLearning: SelfLearningIcon,
        pexesoIcon: PexesoIcon
      }
    },
    progress: {
      schoolBag: SchoolBag,
      test: Test,
      pencil: PencilIcon,
      books: BooksIcon,
      speaker: SpeakerIcon,
      microphone: MicrophoneIcon
    }
  }
}

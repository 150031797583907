import { forwardRef, ReactNode, Ref } from 'react'
import { default as classNames, default as classnames } from 'classnames'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

export type DropdownOption = DropdownMenu.DropdownMenuItemProps & {
  item: ReactNode
}

type Props = DropdownMenu.DropdownMenuProps & {
  disabled?: boolean
  className?: string
  options: DropdownOption[]
  triggerProps?: DropdownMenu.DropdownMenuTriggerProps
  contentProps?: DropdownMenu.DropdownMenuContentProps
}

/**
 * @group Components
 * @category Component
 */
export const Dropdown = forwardRef(
  (
    { className, children, disabled, options = [], triggerProps, contentProps, ...restProps }: Props,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <div className={classnames('flex min-w-[40px] flex-col gap-2', className)}>
        <DropdownMenu.Root {...restProps}>
          <DropdownMenu.Trigger asChild disabled={disabled} {...triggerProps}>
            <div>{children}</div>
          </DropdownMenu.Trigger>

          <DropdownMenu.Content
            autoFocus={false}
            loop
            ref={ref}
            {...contentProps}
            className={classnames(
              'z-50 mt-2 min-w-fit cursor-pointer rounded-lg border-2 border-primary-600 bg-primary-800 shadow-l',
              contentProps?.className
            )}
          >
            {options.map((item, index) => {
              return (
                <DropdownMenu.Item
                  key={index}
                  autoFocus={false}
                  className={classNames(
                    'flex rounded-md px-2 py-[10px] text-sm outline-none',
                    item.disabled ? 'cursor-not-allowed text-primary-600' : 'text-primary-200 hover:bg-primary-700'
                  )}
                  {...item}
                >
                  {item.item}
                </DropdownMenu.Item>
              )
            })}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>
    )
  }
)

Dropdown.displayName = 'Dropdown'

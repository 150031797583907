import { useCallback, useState } from 'react'

export const useVisibleState = <T>(defaultValue: boolean = false, defaultData?: T) => {
  const [visible, setVisible] = useState(defaultValue)
  const [data, setData] = useState<T | undefined>(defaultData)

  const show = useCallback((newData?: T): void => {
    newData && setData(newData)
    setVisible(true)
  }, [])

  const hide = useCallback((): void => {
    setData(undefined)
    setVisible(false)
  }, [])

  return [visible, show, hide, data] as const
}

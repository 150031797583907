import {
  getUseLazyQuery,
  getUseQuery,
  LazyQueryHookOptions,
  MutationHookOptions,
  QueryHookOptions,
  SubscriptionHookOptions,
  useMutation,
  useSubscription
} from '@foxino/graphql-utils'

import { apolloStore } from '@app/modules/common/store/apolloStore'

export const useQuery = getUseQuery(apolloStore.getApolloClient)

export const useLazyQuery = getUseLazyQuery(apolloStore.getApolloClient)

export { useMutation, useSubscription }

export type { LazyQueryHookOptions, MutationHookOptions, QueryHookOptions, SubscriptionHookOptions }

import { forwardRef } from 'react'
import classNames from 'classnames'

type DirectionType = 'vertical' | 'horizontal'

type Props = React.HTMLProps<HTMLInputElement> & {
  direction?: DirectionType
}

/**
 * @group Components
 * @category Component
 */
export const Divider = forwardRef(
  ({ className, direction = 'horizontal', ...restProps }: Props, ref: React.Ref<HTMLInputElement>) => {
    return (
      <span
        className={classNames(
          'block min-h-[1px] min-w-[1px] bg-primary-200',
          direction === 'horizontal' ? 'h-[1px] w-full' : 'h-full w-[1px]',
          className
        )}
        ref={ref}
        {...restProps}
      />
    )
  }
)

Divider.displayName = 'Divider'

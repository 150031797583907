import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

type ApolloStoreType = {
  apolloClient?: ApolloClient<NormalizedCacheObject>
}

const useStore = createWithEqualityFn<ApolloStoreType>(() => ({}), shallow)

export const apolloStore = {
  setApolloClient: (apolloClient: ApolloClient<NormalizedCacheObject>): void => {
    useStore.setState({ apolloClient })
  },

  getApolloClient: (): ApolloClient<NormalizedCacheObject> | undefined => {
    return useStore.getState().apolloClient
  },

  useStore
}

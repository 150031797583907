import { Location } from 'react-router-dom'

import { RouteType } from '@app/config/router/navMenu'

export const checkUrlIsActive = (route: RouteType, location: Location): boolean => {
  const cleanPath = route.to.slice(1, route.to.length)
  const cleanPathname = location.pathname.slice(1, location.pathname.length)
  const matches = cleanPathname.match(cleanPath)?.[0]

  return cleanPath === cleanPathname || !!matches
}

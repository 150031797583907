import { useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import classNames from 'classnames'

import { EnglishFlagRoundIcon } from '@app/assets/flagIcons/round/EnglishFlagRoundIcon'
import { Body, Button, Heading, TextInput } from '@app/components'
import { ClassroomInput, Subject } from '@app/data'
import { useTranslation } from '@app/locales'
import { useClassroomDetail } from '@app/modules/classroom/data'
import { languageToRoundFlagIcon } from '@app/modules/common/utils'
import { LoaderWrapper } from '@app/modules/common/view/LoaderWrapper'

import { validationSchema } from './validationSchema'

type NewClassFormType = ClassroomInput

type Props = {
  onClassroomCreated?: (id: string) => void
  subjects: Subject[]
}

export const NewClassForm = ({ onClassroomCreated, subjects }: Props) => {
  const { t } = useTranslation(['classroom', 'common'])

  const { register, handleSubmit, formState, setValue, control } = useForm<NewClassFormType>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      name: '',
      subject: Subject.ENGLISH
    }
  })
  const { actions, isPending } = useClassroomDetail(undefined)
  const subject = useWatch({ control, name: 'subject' })

  const { errors } = formState

  const onSubmit = async (data: NewClassFormType) => {
    const newClassroomId = await actions.createClassroom(data)

    if (newClassroomId) {
      onClassroomCreated?.(newClassroomId)
    }
  }

  return (
    <LoaderWrapper loading={isPending}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="text-center">
          <Heading size="l" className="mb-2 text-primary-100">
            {t('newClass.title')}
          </Heading>
          <Body className="mb-8 text-primary-200">{t('newClass.description')}</Body>
        </div>

        <div className="mb-2 flex flex-col gap-6">
          {/* Class name */}
          <TextInput
            {...register('name')}
            label={t('newClass.name')}
            placeholder={t('newClass.namePlaceholder')}
            errorText={errors.name && errors.name.message}
            autoFocus
            maxLength={30}
            className="z-30"
          />

          <div className="flex flex-wrap gap-2">
            {/* Subject */}
            {subjects.map((key) => {
              const Icon = languageToRoundFlagIcon[key] || EnglishFlagRoundIcon
              const isSelected = subject === key

              return (
                <div
                  key={key}
                  className={classNames(
                    'z-30 flex w-fit cursor-pointer items-center gap-2 rounded-lg border-2 px-2 py-[6px]',
                    isSelected ? 'border-primary-400 bg-primary-600' : 'border-primary-600'
                  )}
                  onClick={() => setValue('subject', key)}
                >
                  <Icon />
                  <Body size="m" weight="medium" className="text-neutral-100">
                    {t(`subject.${key}`)}
                  </Body>
                </div>
              )
            })}
          </div>

          {/* Submit button */}
          <Button type="submit" className="w-full">
            {t('newClass.create')}
          </Button>
        </div>
      </form>
    </LoaderWrapper>
  )
}

import React, { ReactElement, SVGProps } from 'react'

export const FranceFlagRoundIcon = React.forwardRef(
  ({ width = 24, height = 24, ...props }: SVGProps<SVGSVGElement>, ref: React.Ref<SVGSVGElement>): ReactElement => {
    return (
      <svg
        width={width}
        height={height}
        ref={ref}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_585_28923)">
          <path
            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
            fill="#F0F0F0"
          />
          <path
            d="M23.9999 11.9999C23.9999 6.84037 20.7434 2.44185 16.1738 0.746338V23.2536C20.7434 21.558 23.9999 17.1595 23.9999 11.9999Z"
            fill="#D80027"
          />
          <path
            d="M0.000488281 12.0008C0.000488281 17.1604 3.25704 21.5589 7.8266 23.2544V0.747192C3.25704 2.44271 0.000488281 6.84122 0.000488281 12.0008Z"
            fill="#0052B4"
          />
        </g>
        <defs>
          <clipPath id="clip0_585_28923">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )
  }
)

FranceFlagRoundIcon.displayName = 'FranceFlagRoundIcon'

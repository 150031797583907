import { ImageContent } from '@app/config/constants/ImageContent'
import { Subject } from '@app/data'

export const imagesIdToPicture: Record<Subject, Record<string, string>> = {
  ENGLISH: {
    1: ImageContent.classrooms.subject.ENGLISH[1],
    2: ImageContent.classrooms.subject.ENGLISH[2],
    3: ImageContent.classrooms.subject.ENGLISH[3],
    4: ImageContent.classrooms.subject.ENGLISH[4]
  },
  GERMAN: {
    1: ImageContent.classrooms.subject.GERMAN[1],
    2: ImageContent.classrooms.subject.GERMAN[2],
    3: ImageContent.classrooms.subject.GERMAN[3],
    4: ImageContent.classrooms.subject.GERMAN[4],
    5: ImageContent.classrooms.subject.GERMAN[5]
  },
  FRENCH: {
    1: ImageContent.classrooms.subject.FRENCH[1],
    2: ImageContent.classrooms.subject.FRENCH[2],
    3: ImageContent.classrooms.subject.FRENCH[3],
    4: ImageContent.classrooms.subject.FRENCH[4],
    5: ImageContent.classrooms.subject.FRENCH[5]
  },
  SPANISH: {
    1: ImageContent.classrooms.subject.SPANISH[1],
    2: ImageContent.classrooms.subject.SPANISH[2],
    3: ImageContent.classrooms.subject.SPANISH[3],
    4: ImageContent.classrooms.subject.SPANISH[4],
    5: ImageContent.classrooms.subject.SPANISH[5]
  },
  CZECH: {},
  ITALIAN: {}
}

import { ReactNode } from 'react'
import classNames from 'classnames'
import * as RadixTooltip from '@radix-ui/react-tooltip'

import { Label } from '../Typography'

type Props = RadixTooltip.TooltipContentProps & {
  className?: string
  triggerClassName?: string
  label: string | ReactNode
  description?: string | ReactNode
  children: ReactNode
  disabled?: boolean
  dark?: boolean
  isError?: boolean
}

/**
 * @group Components
 * @category Component
 */
export const Tooltip = ({
  className,
  label,
  description,
  children,
  disabled,
  triggerClassName,
  dark = true,
  isError,
  ...restProps
}: Props) => {
  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root open={disabled ? false : undefined}>
        <RadixTooltip.Trigger className={classNames('flex w-full items-center', triggerClassName)}>
          {children}
        </RadixTooltip.Trigger>

        <RadixTooltip.Portal>
          <div className={classNames('shadow-l')}>
            <RadixTooltip.Content
              sideOffset={15}
              side="right"
              className={classNames(
                'z-50 rounded-lg px-[12px] py-2 shadow-l',
                isError ? 'bg-error-500' : dark ? 'bg-primary-700' : 'bg-white',
                className
              )}
              {...restProps}
            >
              <Label
                size="s"
                className={classNames(dark ? 'text-white' : 'text-primary-800', description && 'font-bold')}
              >
                {label}
              </Label>
              {description && (
                <p className={classNames('text-sm', dark ? 'text-white' : 'text-primary-600')}>{description}</p>
              )}
              <RadixTooltip.Arrow className={dark ? 'fill-primary-700' : 'fill-white'} />
            </RadixTooltip.Content>
          </div>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  )
}
